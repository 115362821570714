export default function modalCookies() {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
        dataLayer.push(arguments);
    }

    var $modalCookies = $('#modal-cookies');

    $(document).on('ready', function () {
        if (getCookie('accept_cookie') == null) {
            if ($modalCookies) {
                $modalCookies.modal('show');
            }
        }
    });

    if ($modalCookies) {
        $('.js-accept-cookie').on('click', function () {
            setCookie('accept_cookie', true, 30);
            $modalCookies.modal('hide');
            allConsentGranted();
        });

        $('.js-decline-cookie').on('click', function () {
            setCookie('accept_cookie', false, 30);
            $modalCookies.modal('hide');
            allConsentDenied();
        });
    }

    function setCookie(name, value, days) {
        var expires = '';
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
            expires = '; expires=' + date.toUTCString();
        }
        document.cookie = name + '=' + (value || '') + expires + '; path=/';
    }

    function getCookie(name) {
        var nameEQ = name + '=';
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0)
                return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

    function allConsentDenied() {
        gtag('consent', 'default', {
            ad_user_data: 'denied',
            ad_personalization: 'denied',
            ad_storage: 'denied',
            analytics_storage: 'denied',
        });
    }

    function allConsentGranted() {
        gtag('consent', 'update', {
            ad_user_data: 'granted',
            ad_personalization: 'granted',
            ad_storage: 'granted',
            analytics_storage: 'granted',
        });
    }
}
